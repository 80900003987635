import { Box, ButtonBase, Stack, Typography } from "@mui/material";
import { formatDateWithJs, makeTxtOverFLow } from "../utils/format";
import { useTranslation } from "react-i18next";


function ConEwbShipmtCard({ data, itemId, handleClick }) {
    const { t } = useTranslation();

    return <ButtonBase component="div" sx={{ borderRadius: 1.4, my: 0.6 }} onClick={handleClick}>
        <Stack px={2} sx={{ backgroundColor: "rgba(223, 218, 248, 0.2)", borderRadius: 1.4, height: 80, border: 1, borderColor: 'rgba(223, 218, 248, 0.9)', width: 900 }}>
            <Stack direction={"row"} alignItems={"center"}>
                <Stack alignItems={"center"} py={1} width={600}>
                    <Stack direction={"row"}>
                        <Typography sx={{ fontSize: 14, fontWeight: "600", color: "primary.main", mr: 6 }}>{itemId}</Typography>
                        <Stack direction={"row"} >
                            <Typography sx={{ fontSize: 12, }}>{data?.picDate ? t("bo.combReqCard.pick") : "Est Pickup:"}&nbsp; {formatDateWithJs(data?.picDate ? data?.picDate : data?.estPicDate)}</Typography>
                            <Typography sx={{ fontSize: 12, ml: 2 }}>{data?.delDate ? t("bo.combReqCard.del") : "Est Delivery:"}&nbsp; {formatDateWithJs(data?.delDate ? data?.delDate : data?.estDelDate)}</Typography>
                        </Stack>
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"}>
                        <Typography sx={{ fontWeight: "600", fontSize: 14 }} >{makeTxtOverFLow(data?.picLocation, 31)}</Typography>
                        <Typography component={'span'} sx={{ fontWeight: "600", fontSize: 30, mx: 2, mb: 0.5 }} > &#8594;</Typography>
                        <Typography component={'span'} sx={{ fontWeight: "600", fontSize: 14 }}> {makeTxtOverFLow(data?.delLocation, 31)}</Typography>
                    </Stack>
                </Stack>
                <Box sx={{ height: "80%", width: 2.5, backgroundColor: '#cacfcc', borderRadius: 1, mb: 1, mx: 5 }} />
                <Stack alignItems={"start"} py={1} mb={1} gap={0.5}>
                    <Typography sx={{ fontSize: 12, color: "primary.main", fontWeight: "500" }}>{"Eway Bill A"}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{":"}&nbsp; {data?.ewayA_no}</Typography>
                    <Typography sx={{ fontSize: 12, color: "primary.main", fontWeight: "500" }}>{"Eway Bill B"}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{":"}&nbsp; {data?.ewayB_no}</Typography>
                    <Typography sx={{ fontSize: 12, color: "primary.main", fontWeight: "500" }}>{"Truck Reg No:"}&nbsp; {data?.truckRegNo}</Typography>
                </Stack>
            </Stack>
        </Stack>
    </ButtonBase>
}

export default ConEwbShipmtCard;