import { Box, Button, Modal, Stack, Typography } from '@mui/material'
import React from 'react';
import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useQueryClient } from 'react-query';
import CustomDropDown from '../../../components/CustomDropDown';
import { customAlert, notify } from '../../../components/notify';
import { useAuthContext } from '../../../context/AuthContext';
import CtrlFillLabelTxtField from '../../../components/CtrlFillLabelTxtField';
import { updateEwbB } from '../../../services/serv_services';

function UpdateEwbB({ modalOpen, handleModalClose, shipmtData }) {

    const [formErrors, setFormErrors] = useState({});
    const { setLoadingScreen } = useAuthContext();
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const [reasonRem, setReasonRem] = useState("");
    const [reason, setReason] = useState("2")

    const ddArr = [
        { value: "2", label: t("bo.shipInfoPg.dueToTrans") },
        { value: "4", label: t("bo.shipInfoPg.firstTime") },
        { value: "1", label: t("bo.shipInfoPg.dueToBrkDOwn") },
        { value: "3", label: t("bo.shipInfoPg.others") }];


    async function handleSubmit(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
        const inputObject = Object.fromEntries(formData); // convert the FormData object to a JSON object
        let errorsObj = validate(inputObject);
        setFormErrors(errorsObj);

        if (Object.keys(errorsObj).length > 0)
            return;

        setLoadingScreen(true);
        try {
            await updateEwbB({
                shipmtId: shipmtData?.shipment?.shipmtId,
                fromPlace: shipmtData?.shipmtPicDel?.picLocation,
                reasonCode: inputObject?.reason,
                reasonRem: inputObject?.reasonRem,
                ewbANo: shipmtData?.shipmtDocs?.ewayA_no,
                truckRegNo: shipmtData?.shipmtTruck?.truckRegNo
            });
            notify("success", "Eway Bill B Updated Successfully");
            handleModalClose();
            queryClient.invalidateQueries();
        } catch (err) {
            console.log(err);
            if (err.status === 409)
                notify("error", JSON.stringify(err.data));
            else
                customAlert(err.status);
        }
        setLoadingScreen(false)
    }


    function handleTxtFieldChange(e) {
        const { value } = e.target;
        setReasonRem(value)
    }

    function handleDDReason(ele) {
        const { value } = ele.target;
        setReason(value);
        setReasonRem("");
    }

    return (
        <Modal
            open={modalOpen}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            {shipmtData ? <form onSubmit={handleSubmit} >
                <Box sx={modalStyle}>
                    <Stack direction={'row'} alignItems={"center"} mb={3}>
                        <Typography sx={{ fontWeight: "bold", fontSize: 17, color: "rgba(0, 0, 0, 0.6)", mr: 1 }}>{t("bo.shipInfoPg.updateEwbB")}</Typography>
                        <Typography sx={{ fontWeight: "bold", fontSize: 16, color: "rgba(0, 0, 0, 0.3)" }}>({shipmtData?.shipment?.shipmtId})</Typography>
                    </Stack>
                    <Stack gap={2} alignItems="center">
                        <CustomDropDown handleDDChange={handleDDReason} title={t("bo.dataInvalid.reason")} name={"reason"} errMsg={formErrors.reason} defaultValue={reason} ddArr={ddArr} width={400} />
                        {reason === "3" && <Stack>
                            <CtrlFillLabelTxtField maxLength={50} fontSize={15} name="reasonRem" errMsg={formErrors.reasonRem} title={t("bo.shipInfoPg.reasonRem")} placeholder={"Type reason remarks here..."} multiline={true} height={103} width={400} value={reasonRem} onChange={handleTxtFieldChange} />
                            <Typography sx={{ fontSize: 14, color: "rgba(0, 0, 0, 0.7)" }}>{reasonRem.length}/50</Typography>
                        </Stack>}
                        <Stack justifyContent={"flex-end"} direction={"row"} sx={{ mt: 2 }} width={400}>
                            <Button variant="text" sx={{ height: 40, width: 120 }} onClick={handleModalClose}>{t("bo.updSts.cancel")}</Button>
                            <Button variant="contained" type='submit' sx={{ height: 40, width: 150 }}>{t("bo.editTrkOpPg.submit")}</Button>
                        </Stack>
                    </Stack>
                </Box>
            </form> : <Typography component={'h1'}>Something went wrong</Typography>}
        </Modal>
    )
}

export default UpdateEwbB;

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 470,
    bgcolor: 'background.paper',
    border: '2px solid #ffff',
    boxShadow: 24,
    px: 4,
    py: 3,
    borderRadius: 2,
};

function validate(values) {
    const errors = {};

    if (!values.reason)
        errors.reason = "Reason is required!";

    if (values.reason === "3") {
        if (!values.reasonRem)
            errors.reasonRem = "Reason remarks is required!";
    }

    return errors;
};