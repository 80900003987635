import { Button, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import BgBox from "../../../components/BgBox";
import { customAlert, notify } from "../../../components/notify";
import { getAllCustByVfyStatus } from "../../../services/req_service";
import { useTranslation } from "react-i18next";
import QueryKey from '../../../QueryKey';
import { useQuery } from 'react-query';
import LoadingScreen from '../../../components/loadingScreen';
import CtrlFillLabelTxtField from "../../../components/CtrlFillLabelTxtField";
import { useState } from "react";
import { Search } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { useAuthContext } from "../../../context/AuthContext";
import { getConEwbShipmts } from "../../../services/shipmt-service";
import { findStateCodeInCity, getCurrDate, isEmpty } from "../../../utils/format";
import { useNavigate } from "react-router-dom";
import route from "../../../Routes";
import ConEwbShipmtCard from "../../../components/ConEwbShipmtCard";
import { genConEwbB } from "../../../services/serv_services";




function GenConEwbPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setLoadingScreen } = useAuthContext();
  const [shData, setShData] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [selection, setSelection] = useState({
    trkOpReqId: "",
  });

  const [pageSize, setPageSize] = useState(4);
  const [selShRows, setSelShRows] = useState([]);
  const columns = [
    {
      field: 'c_at',
      headerName: 'Created Shipments',
      width: 1000,
      align: 'center',
      sortable: true,
      filterable: false,
      renderCell: (params) => <ConEwbShipmtCard data={params.row} itemId={params.row.shipmtId} key={params.row.shipmtId} handleClick={() => handleLinkShipmtClick(params.row.shipmtId)} />
    }
  ]

  function handleLinkShipmtClick(shipmtId) {

    console.log(shipmtId)
    navigate(route.boShipmtInfo + shipmtId);
  }

  function handleSelChange(shipmtIdArr) {
    console.log(",,,,,,,,,,,,,,,,,,,,,,,,,")
    console.log(shipmtIdArr)
    const filteredArray = shData.filter(item => shipmtIdArr.includes(item.shipmtId));
    setSelShRows((prev) => [...filteredArray])
  }

  function handleTxtFieldChange(e) {
    const { name, value } = e.target;
    setSelection(prevSel => { return { ...prevSel, [name]: value } })
  }

  async function getData(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const inputObject = Object.fromEntries(formData); // convert the FormData object to a JSON object
    let errorsObj = validate(inputObject);
    setFormErrors(errorsObj);

    if (Object.keys(errorsObj).length > 0)
      return;

    setLoadingScreen(true);
    try {
      const resData = await getConEwbShipmts({ trkOpReqId: selection?.trkOpReqId });
      console.log(resData)
      if (resData.length === 0) {
        notify("error", "No Shipments Found!");
      } else {
        setShData(resData);
      }

    } catch (err) {
      console.log(err);
      customAlert(err);
    }
    setLoadingScreen(false);
  }

  function handleCancel() {
    navigate(route.boDashboard);
  }


  async function handleGenerate() {
    try {
      if (selShRows.length === 0) {
        notify("error", "No Shipment Selected");
        return
      }

      const isUniqueFromLoc = allHaveSamePickLoc(selShRows);
      if (!isUniqueFromLoc) {
        notify("error", "Selected Shipments have different pickup locations!");
        return
      }

      const hasDupTrkRegNo = allHaveSameTrkRegNo(selShRows);
      if (!hasDupTrkRegNo) {
        notify("error", "Selected Shipments have different Truck Reg No!");
        return
      }
      setLoadingScreen(true);
      const apiBody = formatApiBody(selShRows);
      console.log("=======================apiBody")
      console.log(JSON.stringify(apiBody))

      await genConEwbB({ apiBody: apiBody });

      // navigate(-1, { replace: true });
      setShData([]);
      setSelShRows([]);
      setSelection(prevSel => { return { ...prevSel, trkOpReqId: "" } })
      notify("success", "Eway Bill B Created Successfully");
      setLoadingScreen(false);
    } catch (err) {
      console.log(err);
      if (err.status === 409)
        notify("error", JSON.stringify(err.data));
      else
        customAlert(err.status);
    }
    setLoadingScreen(false);
  };


  const allHaveSameTrkRegNo = (shipmtArr) => {
    if (shipmtArr.length === 1) {
      return true;
    }
    const firstTrkRegNo = shipmtArr[0].truckRegNo;
    for (let i = 1; i < shipmtArr.length; i++) {
      if (shipmtArr[i].truckRegNo !== firstTrkRegNo) {
        return false;
      }
    }
    return true;
  };

  const allHaveSamePickLoc = (shipmtArr) => {
    if (shipmtArr.length === 1) {
      return true; // Empty list can be considered as having the same pickLoc
    }
    const firstPickLoc = shipmtArr[0].picLocation;
    for (let i = 1; i < shipmtArr.length; i++) {
      if (shipmtArr[i].picLocation !== firstPickLoc) {
        return false; // Found a different pickLoc
      }
    }
    return true; // All pickLoc values are the same
  };


  function formatApiBody(shipmtArr) {
    const shipmtIdArr = [];
    const tripSheetEwbBillsArr = [];
    for (let i = 0; i < shipmtArr.length; i++) {
      const e = shipmtArr[i];
      shipmtIdArr.push(e.shipmtId);
      tripSheetEwbBillsArr.push({ ewbNo: parseInt(e.ewayA_no) })
    }

    return {
      shipmtIdArr: [
        ...shipmtIdArr
      ],
      apiData: {
        fromPlace: shipmtArr[0].picLocation,
        fromState: findStateCodeInCity(shipmtArr[0].picLocation),
        transMode: "1",
        tripSheetEwbBills: [
          ...tripSheetEwbBillsArr
        ],
        vehicleNo: shipmtArr[0].truckRegNo,
        transDocDate: getCurrDate()
      }
    }
  }


  return (

    <Box height={"100%"} width={"100%"}>

      <Stack direction={'row'} justifyContent='space-between' mb={1} >
        <Typography variant='h5' sx={{ fontWeight: "bold" }}>{t("bo.genConEwb.genConEwbB")} </Typography>
      </Stack>

      <BgBox height={"95%"} px={4} py={1}>
        <form onSubmit={getData} noValidate id='getsh' style={{ padding: 0, height: "100%" }}>
          <Stack>
            <Stack>
              <Typography sx={{ fontWeight: "bold", fontSize: 16, color: "rgba(131, 146, 171, 0.7)" }}>{t("bo.genConEwb.trkOpReqId")}</Typography>
              <Stack direction={'row'} alignItems={"center"} >
                <CtrlFillLabelTxtField name="trkOpReqId" title={""} errMsg={formErrors.trkOpReqId} fontSize={14} height={38} value={selection?.trkOpReqId} onChange={handleTxtFieldChange} />
                <Stack direction={'row'} justifyContent={"space-between"} width={1000} >
                  <Button variant='contained' sx={{ ml: 2, height: 38, mt: 0.7 }} startIcon={<Search />} color='primary' type="submit">{t("bo.genConEwb.getShipmts")}</Button>
                  {shData.length > 0 && <Stack direction={'row'} justifyContent={"space-between"} gap={1}>
                    <Button variant='outlined' sx={{ height: 38, mt: 0.7, width: 150 }} color='error' onClick={handleCancel}>{t("bo.genConEwb.cancel")}</Button>
                    <Button variant='contained' sx={{ height: 38, mt: 0.7, width: 150 }} color='success' onClick={handleGenerate}>{t("bo.genConEwb.gen")}</Button>
                  </Stack>}
                </Stack>
              </Stack>
            </Stack>
          </Stack>

          {shData.length > 0 && <Box sx={{ height: "80%", width: '100%', mt: 2 }}>
            <DataGrid
              rows={shData}
              columns={columns}
              checkboxSelection
              onSelectionModelChange={handleSelChange}
              getRowId={(row) => row.shipmtId}
              rowsPerPageOptions={[4, 8, 10, 20]}
              headerHeight={40}
              hideFooter={true}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              isRowSelectable={(params) => isEmpty(params.row.ewayB_no)
              }
              sx={{ fontSize: 14, height: "100%" }}
              getRowHeight={() => 'auto'}
            />
          </Box>}


        </form>
      </BgBox>
    </Box>
  )
}

export default GenConEwbPage;

function validate(values) {
  const errors = {};

  if (!values.trkOpReqId)
    errors.trkOpReqId = "Request Id is required!";


  return errors;
};