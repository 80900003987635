import { Button, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React, { useState } from 'react'
import { capFirstLetter, formatDateTime, formatDateWithJs, getLocalStrg, makeTxtOverFLow, } from '../../../utils/format';
import StyledTableContainer from '../../../components/StyledTableContainer';
import { useTranslation } from "react-i18next";
import PymtInfo from './PymtInfo';


function AgPymtTable({ pymtRows, height, columns }) {
    const { t } = useTranslation();
    const [selRow, setSelRow] = useState(null);
    const [pymtInfoOpen, setPymtInfoOpen] = useState(false);
    const handlePymtInfoClose = () => {
        setPymtInfoOpen(false);

    }
    const handlePymtInfoOpen = () => {
        setPymtInfoOpen(true);
    }
console.log("11111")
    console.log(columns)

    async function handleClick(custRow) {
        setSelRow(custRow);
        handlePymtInfoOpen();
    }


    return (
        <StyledTableContainer height={height ?? "83%"}>
            <TableHead>
                <TableRow>
                    {columns.map((column) => (
                        <TableCell
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                            sx={{ color: "#8392AB", fontWeight: "bold", py: 1 }}
                            key={column.id}
                        >
                            {column.label}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {(pymtRows || []).map((reqRow) => {
                    return (
                        <TableRow onClick={() => { handleClick(reqRow); }} hover tabIndex={-1} key={reqRow.truckOpReqId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                            {columns.map((column) => {
                                const value = reqRow[column.id];
                                return (
                                    (column.id === 'agent') ?
                                        <TableCell key={column.id} align={column.align} >
                                            <Button variant="text" sx={{ color: "#000000" }} onClick={() => { }}>
                                                {capFirstLetter(reqRow.agName)} - {reqRow.agentCode}
                                            </Button>
                                        </TableCell> : (column.id === 'c_at') ?
                                            <TableCell key={column.id} align={column.align} sx={{ textAlign: "left" }}>
                                                {formatDateTime(reqRow.c_at)}
                                            </TableCell> : (column.id === 'issuedDate') ?
                                                <TableCell key={column.id} align={column.align} sx={{ textAlign: "left" }}>
                                                    {formatDateWithJs(reqRow?.issuedDate)}
                                                </TableCell> : (column.id === 'paidAmt') ?
                                                    <TableCell key={column.id} align={column.align} sx={{ textAlign: "left" }}>
                                                        <span name="Rs">&#8377;</span>  {getLocalStrg(reqRow?.paidAmt)}
                                                    </TableCell> : (column.id === 'revenue') ?
                                                        <TableCell key={column.id} align={column.align} sx={{ textAlign: "left" }}>
                                                            <span name="Rs">&#8377;</span>  {getLocalStrg(reqRow?.revenue)}
                                                        </TableCell> : (column.id === 'agComPer') ?
                                                            <TableCell key={column.id} align={column.align} sx={{ textAlign: "left" }}>
                                                                {`${reqRow?.agComPer}%`}
                                                            </TableCell> : (column.id === 'shipmts') ?
                                                                <TableCell key={column.id} align={column.align} sx={{ textAlign: "left" }}>
                                                                    {`${reqRow?.shipmts.length}`}
                                                                </TableCell> :
                                                                <TableCell key={column.id} align={column.align} sx={{ textAlign: "left" }} >
                                                                    {column.format && typeof value === 'number' ? column.format(value) : makeTxtOverFLow(value, 30)}
                                                                </TableCell>);
                            })}
                        </TableRow>
                    );
                })}
            </TableBody>
            {selRow && <PymtInfo handleModalClose={handlePymtInfoClose} modalOpen={pymtInfoOpen} data={selRow} />}

        </StyledTableContainer>
    )
}

export default AgPymtTable