import { findStateCodeInCity, getCurrDate } from "../utils/format";
import { getData, postData, postDataErrObj, putData } from "./rest-api-helper"

export async function getDDCities(txt) {

    if (!txt)
        return []

    const details = {
        urlPath: "/services/cities/" + txt
    }

    const res = await getData(details);
    console.log(res, "---------------getDDCities");
    return res || [];
}


export async function getDistance({ fromId, toId }) {

    if (!fromId || !toId)
        return []

    const details = {
        urlPath: "/services/distance",
        queryParams: { fromId, toId }
    }

    const res = await getData(details);
    console.log(res, "---------------getDistance");
    return res;
}

export async function getRcDetails({ regNo }) {
    const details = {
        urlPath: "/services/verifyRC/" + regNo,
    }

    const res = await getData(details);
    console.log(res, "---------------getRcDetails");
    return res;
}

export async function putAdrVerify(custId, status) {
    const details = {
        urlPath: `/services/aadhaarVerify`,
        queryParams: {
            custId, status
        }
    }
    const res = await putData(details);
    console.log(res + "---------------putAdrVerify");
    return res;
}

export async function getPendingAdrVfyByUser({ userId }) {
    const details = {
        urlPath: `/services/getPendingAdrVfyByUser/${userId}`
    }

    const res = await getData(details);
    console.log(res, "---------------getPendingAdrVfyByUser");
    return res;
}

export async function getAllPendingAdrVfy() {
    const details = {
        urlPath: `/services/aadhaarVerify`
    }

    const res = await getData(details);
    console.log(res, "---------------getAllPendingAdrVfy");
    return res || [];
}

export async function getEwayBillDetails({ ewbNo }) {
    const details = {
        urlPath: `/services/mastergst/getEwayBill/${ewbNo}`
    }
    const res = await getData(details);
    console.log(res, "---------------getEwayBill");
    return res;
}



export async function updateEwbB({ shipmtId, fromPlace, reasonCode, reasonRem, ewbANo, truckRegNo }) {
    const details = {
        urlPath: `/services/mastergst/updateEwbB`,
        body: {
            shipmtId: shipmtId,
            apiData: {
                fromPlace: fromPlace,
                fromState: findStateCodeInCity(fromPlace),
                reasonCode: reasonCode,
                reasonRem: reasonRem ?? "",
                transMode: "1",
                ewbNo: parseInt(ewbANo),
                vehicleNo: truckRegNo,
                transDocDate: getCurrDate()
            }
        }
    }
    console.log(details.body.apiData)
    const res = await postDataErrObj(details);
    console.log(res + "---------------updateEwbB");
    return res;
}

export async function genConEwbB({ apiBody }) {
    const details = {
        urlPath: `/services/mastergst/genConEwbB`,
        body: {
            ...apiBody
        }
    }
    console.log(details.body.apiData)
    const res = await postDataErrObj(details);
    console.log(res + "---------------genConEwbB");
    return res;
}
