import { Box, Button, Checkbox, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import route from '../Routes';
import { capFirstLetter, formatDateTime, formatWeight, getLocalStrg, makeTxtOverFLow, shipmtStClrs } from '../utils/format';
import StyledTableContainer from './StyledTableContainer';
import { useTranslation } from "react-i18next";




function UnsettleShipmtTable({ shipmtRows, height, allShipmtRows, selectedRows, setSelectedRows, handleNewAmt, revenue, setRevenue }) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [selectAll, setSelectAll] = useState(false);



    console.log("selectAll--------------------------");
    console.log(selectAll)
    console.log("selectedRows--------------------------");
    console.log(selectedRows)


    const columns = [
        {
            id: 'checkbox',
            label: "",
            align: 'left',
            minWidth: 30
        },
        {
            id: 'shipmtId',
            label: t("bo.shipTable.shpId"),
            align: 'left',
            minWidth: 190
        },
        {
            id: 'status',
            label: t("bo.shipTable.sts"),
            align: 'left',
            minWidth: 80
        },
        {
            id: 'cust_finalPrc',
            label: t("bo.trkOpReqInfoPg.finalPrc1"),
            align: 'left',
            minWidth: 150
        },
        {
            id: 'custPaidAmt',
            label: t("bo.shipInfoPg.custPaidAmt"),
            align: 'left',
            minWidth: 150
        },

        {
            id: 'cust',
            label: t("bo.shipTable.cust"),
            align: 'left',
            minWidth: 150
        },
        {
            id: 'trkOp',
            label: t("bo.shipTable.trkOp"),
            minWidth: 150
        },
        {
            id: 'load',
            label: t("bo.shipTable.load"),
            align: 'left',
            minWidth: 190
        },
        {
            id: 'location',
            label: t("bo.shipTable.location"),
            align: 'left',
            minWidth: 190
        },
        {
            id: 'truckRegNo',
            label: t("bo.shipTable.trkNo"),
            minWidth: 80
        },

        {
            id: 'c_at',
            label: t("bo.shipTable.createdAt"),
            align: 'left',
            minWidth: 100
        }
    ];

    async function handleNameClick(reqRow) {
        navigate(route.boCustInfo + reqRow.custId);
    }

    async function handleReqIdClick(reqRow) {
        navigate(route.boShipmtInfo + reqRow.shipmtId);
    }

    function shipmtStatus(status) {
        let stObj = shipmtStClrs(status);

        return <Box sx={{ backgroundColor: stObj.bgColor, height: 30, width: 100, borderRadius: 10, display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Typography sx={{ fontWeight: "600", fontSize: status == 'awaitingUnloading' ? 10 : 12, color: stObj.txtColor }}>{stObj.label}</Typography>
        </Box>
    }

    const handleCheckboxChange = (id, finalPricing) => {
        const newSelectedRows = [...selectedRows];
        const index = newSelectedRows.indexOf(id);

        if (index === -1) {
            newSelectedRows.push(id);
            setRevenue((a) => a + finalPricing);
        } else {
            newSelectedRows.splice(index, 1);
            setRevenue((a) => a - finalPricing);
        }
        setSelectedRows(newSelectedRows);
    };

    const handleSelectAllChange = () => {
        if (selectAll) {
            setSelectedRows([]);
            setRevenue(0);
        } else {
            let total = 0;
            const allRowIds = allShipmtRows.map((row) => row.shipmtId);
            setSelectedRows(allRowIds);
            for (let i = 0; i < allShipmtRows.length; i++) {
                const e = allShipmtRows[i];
                total = total + e.cust_finalPrc;
            }
            setRevenue((a) => a + total);
        }
        setSelectAll(!selectAll);
    };

    const isSelected = (id) => selectedRows.indexOf(id) !== -1;

    return (
        <StyledTableContainer height={height ?? "83%"}>
            <TableHead>
                <TableRow>
                    {columns.map((column) => (
                        (column.id === 'checkbox') ? <TableCell>
                            <Checkbox
                                checked={selectAll}
                                indeterminate={selectedRows.length > 0 && selectedRows.length < shipmtRows.length}
                                onChange={handleSelectAllChange}
                            />
                        </TableCell> :
                            <TableCell
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                                sx={{ color: "#8392AB", fontWeight: "bold", py: 1 }}
                                key={column.id}
                            >
                                {column.label}
                            </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {(shipmtRows || []).map((reqRow) => {
                    return (
                        <TableRow hover tabIndex={-1} key={reqRow.shipmtId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                            {columns.map((column) => {
                                const value = reqRow[column.id];
                                return (
                                    (column.id === 'cust') ?
                                        <TableCell key={column.id} align={column.align}>
                                            <Button variant="text" sx={{ color: "#000000", textAlign: "left" }} onClick={() => { handleNameClick(reqRow); }}>
                                                {capFirstLetter(reqRow.custFName) + " " + capFirstLetter(reqRow.custLName)}&nbsp; &nbsp; &nbsp;({reqRow.custMobile1})
                                            </Button>
                                        </TableCell> : (column.id === 'trkOp') ?
                                            <TableCell key={column.id} align={column.align}>
                                                <Button variant="text" sx={{ color: "#000000", textAlign: "left" }} onClick={() => { handleNameClick(reqRow); }}>
                                                    {capFirstLetter(reqRow.trkOpFName) + " " + capFirstLetter(reqRow.trkOpLName)}&nbsp; &nbsp; &nbsp;({reqRow.trkOpMobile1})
                                                </Button>
                                            </TableCell> : (column.id === 'location') ?
                                                <TableCell key={column.id} align={column.align} sx={{ textAlign: "left" }}>
                                                    {makeTxtOverFLow(reqRow.shipmtPicLoc, 30) + " - " + makeTxtOverFLow(reqRow.shipmtDelLoc, 30)}
                                                </TableCell> : (column.id === 'load') ?
                                                    <TableCell key={column.id} align={column.align} sx={{ textAlign: "left" }}>
                                                        {capFirstLetter(reqRow.matType)} &nbsp;{reqRow.matType == 'Others' ? `(${reqRow.matTypeFreeTxt})` : ''}  &nbsp; - &nbsp; {formatWeight({ val: reqRow.weight, unit: reqRow.weightUnit })}  &nbsp;  - &nbsp;  {capFirstLetter(reqRow.loadType)}
                                                    </TableCell> : (column.id === 'shipmtId') ?
                                                        <TableCell key={column.id} align={column.align} >
                                                            <Button variant="text" sx={{ color: "#000000", textAlign: "left" }} onClick={() => { handleReqIdClick(reqRow); }}>
                                                                {reqRow.shipmtId}
                                                            </Button>
                                                        </TableCell> : (column.id === 'c_at') ?
                                                            <TableCell key={column.id} align={column.align} sx={{ textAlign: "left" }}>
                                                                {formatDateTime(reqRow.c_at)}
                                                            </TableCell> : (column.id === "status") ?
                                                                <TableCell key={column.id} align={column.align} sx={{ textAlign: "left" }}>
                                                                    {shipmtStatus(reqRow.currStatus)}
                                                                </TableCell> : (column.id === "checkbox") ?
                                                                    <TableCell key={column.id} align={column.align} sx={{ textAlign: "left" }}>
                                                                        <Checkbox
                                                                            checked={isSelected(reqRow.shipmtId)}
                                                                            onChange={() => handleCheckboxChange(reqRow.shipmtId, reqRow.cust_finalPrc)}
                                                                        />
                                                                    </TableCell> : (column.id === 'cust_finalPrc') ?
                                                                        <TableCell key={column.id} align={column.align} sx={{ textAlign: "left" }}>
                                                                            <span name="Rs">&#8377;</span> {getLocalStrg(reqRow.cust_finalPrc)}
                                                                        </TableCell> : (column.id === 'custPaidAmt') ?
                                                                            <TableCell key={column.id} align={column.align} sx={{ textAlign: "left" }}>
                                                                                <span name="Rs">&#8377;</span> {getLocalStrg(reqRow.custPaidAmt)}
                                                                            </TableCell> :
                                                                            <TableCell key={column.id} align={column.align} sx={{ textAlign: "left" }} >
                                                                                {column.format && typeof value === 'number' ? column.format(value) : capFirstLetter(value)}
                                                                            </TableCell>);
                            })}
                        </TableRow>
                    );
                })}
            </TableBody>

        </StyledTableContainer>
    )
}

export default UnsettleShipmtTable