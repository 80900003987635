import { Box, Button, ButtonBase, Rating, Step, StepLabel, Stepper, Typography, useMediaQuery } from '@mui/material'
import { Stack } from '@mui/system'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import BgBox from '../../../components/BgBox'
import LoadingScreen from '../../../components/loadingScreen'
import { customAlert, notify } from '../../../components/notify'
import ScrollBox from '../../../components/ScrollBox'
import { capFirstLetter, formatDateTime, formatDateWithJs, formatSess, formatShipmtStatus, formatWeight, getFormatedUnit, getLocalStrg, getTruckName, shipmtStClrs } from '../../../utils/format'
import phoneSVG from '../../../assets/svg/Phone.svg'
import LabelTxt from '../../../components/LabelTxt'
import LabelTxtWithIcon from '../../../components/LabelTxtWithIcon'
import calendSVG from "../../../assets/svg/calend.svg"
import weightSVG from "../../../assets/svg/weight.svg"
import QueryKey from '../../../QueryKey'
import { getSingleShipmt } from '../../../services/shipmt-service'
import trackSVG from "../../../assets/svg/track.svg"
import { useTranslation } from "react-i18next";
import LabelTxtWithIconBtn from '../../../components/LabelTxtWithIconBtn'
import fileIconSVG from "../../../assets/svg/fileIcon.svg"
import UnderLineTxt from '../../../components/UnderLineTxt'
import route from '../../../Routes'
import CustPymtTable from './CustPymtTable'
import FileViewLabel from '../../../components/FileViewLabel'
import InvViewLabel from '../../../components/InvViewLabel'
import FillLabelTxtField from '../../../components/FillLabelTxtField'
import { CurrencyRupee, Edit } from '@mui/icons-material'
import Feedback from './Feedback'
import UpdateDetails from './UpdateDetails'
import { postRazorpay, postRazorpayError, postRazorpayValidate } from '../../../services/razorpay-service'
import logo from "../../../assets/svg/logo.svg";
import { useAuthContext } from '../../../context/AuthContext'
import deletedSVG from '../../../assets/svg/deleted.svg';

function AgShipmtInfoPage() {
    const { setLoadingScreen } = useAuthContext();
    const navigate = useNavigate();
    const { shipmtId } = useParams();
    const { t } = useTranslation();
    const isLarge = useMediaQuery("(min-width: 600px)");
    const { isLoading, isError, error, data: shipmtData } = useQuery([QueryKey.singleShipmt, shipmtId], () => getSingleShipmt(shipmtId));


    const titleTxtStyle = { fontWeight: "bold", fontSize: 17, color: "rgba(0, 0, 0, 0.3)" }
    const labelStyle = { fontSize: 14, fontWeight: "550" };
    // const secTxtStyle = { fontWeight: "600", color: "rgba(0, 0, 0, 0.5)", fontSize: 15 }
    // const iconStyle = { height: 20 }
    const boxStyle = { bgcolor: "rgba(0, 0, 0, 0.05)", borderRadius: 10, fontWeight: "600" }

    const [custRatMdl, setCustRatMdl] = useState(false);
    const handlesetCustRatMdlMdlClose = () => setCustRatMdl(false);
    const handlesetCustRatMdlMdlOpen = () => setCustRatMdl(true);

    const [updateDetMdl, setUpdateDetMdl] = useState(false);
    const handlesetUpdateDetMdlMdlClose = () => setUpdateDetMdl(false);
    const handlesetUpdateDetMdlMdlOpen = () => setUpdateDetMdl(true);

    function getShipmtStatus(status) {
        let stObj = shipmtStClrs(status);
        return <Typography sx={{ fontWeight: "600", fontSize: 14, color: stObj.txtColor }}>{stObj.label}</Typography>
    }


    function getFullName(fName, lName) {
        return capFirstLetter(fName) + " " + capFirstLetter(lName)
    }

    function getDateSess(dt, sess) {
        return formatDateWithJs(dt) + " & " + formatSess(sess)
    }

    async function handlePayNow(sData) {
        setLoadingScreen(true)
        try {
            let body = {
                amount: sData?.shipmtPricing?.cust_finalPrc - sData?.shipmtPricing?.custPaidAmt,
                // amount: '1',
                currency: "INR",
                shipmtId,
                name: sData?.agentData.agName,
                email: sData?.agentData.agMail,
                contact: sData?.agentData.agMobile
            }

            if (body.amount < 1) {
                setLoadingScreen(false)
                notify("error", "Amount should be more than Rs 1");
                return;
            }

            const order = await postRazorpay(body)
            console.log(order);

            var options = {
                // key: "rzp_test_xJODxbXLvFupzt", //test
                key: "rzp_live_3voE4naRlLRGq0", //live
                amount: body.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                currency: body.currency,
                name: "DLX LOGISTICS PVT LTD", //your business name
                description: "Shipment Transaction",
                image: logo,
                order_id: order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
                handler: async function (response) {
                    let b = {
                        ...response,
                        shipmtId: body.shipmtId,
                        amount: body.amount
                    }
                    const jsonRes = await postRazorpayValidate(b)
                    console.log(jsonRes);
                    setLoadingScreen(true)
                    window.location.reload();
                },
                prefill: {
                    //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
                    name: body.name, //your customer's name
                    email: body.email,
                    contact: body.contact, //Provide the customer's phone number for better conversion rates
                },
                notes: {
                    address: `Website - Shipment Id ${body.shipmtId}`,
                },
                theme: {
                    color: "#5E47DD",
                },
            };
            var rzp1 = new window.Razorpay(options);
            rzp1.on("payment.failed", async function (response) {
                let validRes = {
                    code: response.error.code,
                    description: response.error.description,
                    source: response.error.source,
                    step: response.error.step,
                    reason: response.error.reason
                }
                await postRazorpayError({ orderId: order.id, validRes });
                notify("error", validRes.description);
            });
            rzp1.open();
            setLoadingScreen(false)
        } catch (error) {
            console.log(error)
            setLoadingScreen(false)
            customAlert(error);
            window.location.reload();
        }

    }

    if (!shipmtData && shipmtId)
        return <h2>Invalid Shipment</h2>

    if (isError) {
        customAlert(error);
        return <h2>Something went wrong</h2>
    }

    if (isLoading)
        return <Box sx={{ position: "absolute", top: 0, left: 0 }}><LoadingScreen /></Box>

    if (shipmtData === null)
        return <h2>Invalid Shipment</h2>




    return (
        <>
            <ScrollBox height={"100%"}>
                <Stack position={"relative"}>
                    <Stack direction={'row'} justifyContent='space-between' mb={2} >
                        <Stack direction={'row'} alignItems={"end"}>
                            <Typography variant='h5' sx={{ fontWeight: "bold", mr: 1 }}>{t("bo.shipInfoPg.ship")}</Typography>
                            <Typography sx={titleTxtStyle} mr={1}>({shipmtId})</Typography>
                        </Stack>
                        <Stack direction={'row'} gap={1.5}>
                            {(shipmtData?.shipment?.currStatus === "pending") && <Button variant='contained' color='primary' onClick={handlesetUpdateDetMdlMdlOpen}>{t("bo.agent.updateDet")}</Button>}
                        </Stack>
                    </Stack>

                    <Stack direction={"row"} alignItems={"start"} gap={1.2}>
                        <Stack width={isLarge ? "49.5%" : "70%"} gap={1}>
                            <BgBox px={4} py={3} >
                                <Typography sx={titleTxtStyle} mb={1.5}>{t("bo.shipInfoPg.shipInfo")}</Typography>
                                <Stack direction={"row"} alignItems="center" mb={2}>
                                    <LabelTxt label={t("bo.shipInfoPg.shipId")} value={shipmtData?.shipment?.shipmtId} minWidth={260} maxWidth={260} />
                                    <Stack minWidth={260} maxWidth={260}>
                                        <Typography sx={labelStyle}>{t("bo.shipInfoPg.sts")}</Typography>
                                        {getShipmtStatus(shipmtData?.shipment?.currStatus)}
                                    </Stack>
                                </Stack>
                                <Stack direction={"row"} alignItems="center">
                                    <ButtonBase component={"div"} onClick={() => navigate(route.boCustInfo + shipmtData?.shipment?.custId)}>
                                        <Stack direction={"row"} alignItems="center">
                                            <LabelTxtDel label={t("bo.shipInfoPg.cust")} custData={shipmtData?.custData} value={getFullName(shipmtData?.custData?.fName, shipmtData?.custData?.lName)} minWidth={150} maxWidth={150} />
                                            <LabelTxtWithIcon label={""} value={shipmtData?.custData?.mobile1} icon={phoneSVG} minWidth={130} maxWidth={130} />
                                        </Stack>
                                    </ButtonBase>

                                </Stack>

                                <Stack direction={"row"} alignItems="center" mt={2}>
                                    <ButtonBase component={"div"} onClick={() => navigate(route.boCustReqInfo + shipmtData?.shipment?.custReqId)}>
                                        <LabelTxt label={t("bo.shipInfoPg.custReqId")} value={shipmtData?.shipment?.custReqId} minWidth={270} maxWidth={270} />
                                    </ButtonBase>

                                </Stack>
                            </BgBox>

                            {(shipmtData?.shipment?.currStatus === "completed") &&
                                <Stack position={"relative"}>
                                    <BgBox px={4} py={3} >
                                        <Typography sx={titleTxtStyle} mb={1.5}>{t("bo.custInfoPg.shipmtRatingByCust")}</Typography>

                                        <Stack direction={"row"} mt={2} gap={20} mb={2} >
                                            <Stack gap={3}>
                                                <Stack sx={{ width: 500 }}>
                                                    <Typography style={{
                                                        fontWeight: "600",
                                                        fontSize: 15
                                                    }} component={'label'} sx={{ mb: 1 }} >{t("bo.custInfoPg.rating")}</Typography>
                                                    {shipmtData && <Rating value={shipmtData?.shipmtCustRating?.rating ?? 0} readOnly precision={0.1} />}
                                                </Stack>
                                                {shipmtData && <FillLabelTxtField name="feedback" title={t("bo.custInfoPg.feedback")} defaultValue={shipmtData?.shipmtCustRating?.feedback} readOnly={true} sx={{ fontSize: 15, height: 38 }} multiline={true} height={103} />}
                                            </Stack>
                                            <Stack>
                                            </Stack>
                                        </Stack>
                                    </BgBox>
                                    <EditBtn onClick={handlesetCustRatMdlMdlOpen} />
                                </Stack>}


                            <BgBox px={4} py={3} >
                                <Typography sx={titleTxtStyle} mb={1.5}>{t("bo.shipInfoPg.pickDet")}</Typography>
                                <Stack direction={"row"} alignItems="center" mb={2} gap={2}>
                                    <LabelTxt label={t("bo.shipInfoPg.consignorName")} value={shipmtData?.shipmtPicDel?.cnorName} minWidth={280} maxWidth={280} />
                                    <LabelTxt label={t("bo.shipInfoPg.consignorGst")} value={shipmtData?.shipmtPicDel?.cnorGST} minWidth={280} maxWidth={280} />
                                </Stack>
                                <Stack direction={"row"} alignItems="center" mb={2} gap={2}>
                                    <LabelTxt label={t("bo.addCustReqPg.cnorMobile")} value={shipmtData?.shipmtPicDel?.cnorMobile} minWidth={280} maxWidth={280} />
                                    <LabelTxt label={t("bo.addCustReqPg.cnorEmail")} value={shipmtData?.shipmtPicDel?.cnorEmail} minWidth={280} maxWidth={280} />
                                </Stack>
                                <Box sx={boxStyle} height={1.5} mt={2} width={"100%"} />
                                <UnderLineTxt boxStyle={boxStyle} label={t("bo.shipInfoPg.estDate")} value={getDateSess(shipmtData?.shipmtPicDel?.estPicDate, shipmtData?.shipmtPicDel?.estPicSession)} gap={230} />
                                {shipmtData?.shipmtPicDel?.picDate && <UnderLineTxt boxStyle={boxStyle} label={t("bo.shipInfoPg.date")} value={getDateSess(shipmtData?.shipmtPicDel?.picDate, shipmtData?.shipmtPicDel?.picSession)} gap={230} />}
                                <UnderLineTxt boxStyle={boxStyle} label={t("bo.shipInfoPg.location")} value={shipmtData?.shipmtPicDel?.picLocation} gap={230} />
                                <UnderLineTxt boxStyle={boxStyle} label={t("bo.shipInfoPg.address")} value={shipmtData?.shipmtPicDel?.picAddress} gap={230} />
                                <Box sx={boxStyle} height={1.5} my={2} width={"100%"} />
                                <Stack direction={"row"} alignItems="center" gap={1}>
                                    <LabelTxt label={t("bo.shipInfoPg.inchargeName")} value={shipmtData?.shipmtInch?.picIncName} minWidth={210} maxWidth={210} />
                                    <LabelTxt label={t("bo.shipInfoPg.mNum1")} value={shipmtData?.shipmtInch?.picInchMobile1} minWidth={160} maxWidth={160} />
                                    {shipmtData?.shipmtInch?.picInchMobile2 && <LabelTxt label={t("bo.shipInfoPg.mNum2")} value={shipmtData?.shipmtInch?.picInchMobile2} minWidth={160} maxWidth={160} />}
                                </Stack>
                            </BgBox>

                            <BgBox px={4} py={3} >
                                <Typography sx={titleTxtStyle} mb={1.5}>{t("bo.shipInfoPg.delPointDet")}</Typography>
                                <Stack direction={"row"} alignItems="center" mb={2} gap={2}>
                                    <LabelTxt label={t("bo.shipInfoPg.consigneeName")} value={shipmtData?.shipmtPicDel?.cneeName} minWidth={280} maxWidth={280} />
                                    <LabelTxt label={t("bo.shipInfoPg.consigneeGst")} value={shipmtData?.shipmtPicDel?.cneeGST} minWidth={280} maxWidth={280} />
                                </Stack>
                                <Stack direction={"row"} alignItems="center" mb={2} gap={2}>
                                    <LabelTxt label={t("bo.addCustReqPg.cneeMobile")} value={shipmtData?.shipmtPicDel?.cneeMobile} minWidth={280} maxWidth={280} />
                                    <LabelTxt label={t("bo.addCustReqPg.cneeEmail")} value={shipmtData?.shipmtPicDel?.cneeEmail} minWidth={280} maxWidth={280} />
                                </Stack>
                                <Box sx={boxStyle} height={1.5} mt={2} width={"100%"} />
                                <UnderLineTxt boxStyle={boxStyle} label={t("bo.shipInfoPg.estDate")} value={getDateSess(shipmtData?.shipmtPicDel?.estDelDate, shipmtData?.shipmtPicDel?.estDelSession)} gap={230} />
                                {shipmtData?.shipmtPicDel?.delDate && <UnderLineTxt boxStyle={boxStyle} label={t("bo.shipInfoPg.date")} value={getDateSess(shipmtData?.shipmtPicDel?.delDate, shipmtData?.shipmtPicDel?.delSession)} gap={230} />}
                                <UnderLineTxt boxStyle={boxStyle} label={t("bo.shipInfoPg.location")} value={shipmtData?.shipmtPicDel?.delLocation} gap={230} />
                                <UnderLineTxt boxStyle={boxStyle} label={t("bo.shipInfoPg.address")} value={shipmtData?.shipmtPicDel?.delAddress} gap={230} />
                                <Box sx={boxStyle} height={1.5} my={2} width={"100%"} />
                                <Stack direction={"row"} alignItems="center" gap={1}>
                                    <LabelTxt label={t("bo.shipInfoPg.inchargeName")} value={shipmtData?.shipmtInch?.delInchName} minWidth={210} maxWidth={210} />
                                    <LabelTxt label={t("bo.shipInfoPg.mNum1")} value={shipmtData?.shipmtInch?.delInchMobile1} minWidth={160} maxWidth={160} />
                                    {shipmtData?.shipmtInch?.delInchMobile2 && <LabelTxt label={t("bo.shipInfoPg.mNum2")} value={shipmtData?.shipmtInch?.delInchMobile2} minWidth={160} maxWidth={160} />}
                                </Stack>
                            </BgBox>

                            <BgBox px={4} py={3} >
                                <Typography sx={titleTxtStyle} mb={1.5}>{t("bo.shipInfoPg.drivDet")}</Typography>
                                <Stack direction={"row"} alignItems="center" gap={1} mb={3}>
                                    <LabelTxt label={t("bo.shipInfoPg.drivName")} value={shipmtData?.shipmtDriver?.drivName} minWidth={180} maxWidth={180} />
                                    <LabelTxt label={t("bo.shipInfoPg.licNo")} value={shipmtData?.shipmtDriver?.drivLicNo} minWidth={180} maxWidth={180} />
                                </Stack>
                            </BgBox>

                            <BgBox px={4} py={3} >
                                <Typography sx={titleTxtStyle} mb={1.5}>{t("bo.shipInfoPg.priceDet")}</Typography>
                                <Typography sx={{ fontSize: 14, fontWeight: "550", mb: 0.8 }}>{t("bo.shipInfoPg.custPrice")}</Typography>
                                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} py={1} px={8} sx={{ backgroundColor: "rgba(223, 218, 248, 0.2)", borderRadius: 1.4, height: 55, border: 1, borderColor: 'rgba(223, 218, 248, 0.9)', width: 580 }}>
                                    <Typography sx={{ fontSize: 16, fontWeight: "600", color: "primary.main" }}>{getFormatedUnit(shipmtData?.shipmtPricing?.custPayType)}</Typography>
                                    <Typography sx={{ fontSize: 20, fontWeight: "bold" }}><span name="Rs">&#8377;</span> {getLocalStrg(shipmtData?.shipmtPricing?.cust_finalPrc)}</Typography>
                                </Stack>

                            </BgBox>

                            <BgBox px={4} py={3} >
                                <Stack direction={"row"} justifyContent={"space-evenly"}>
                                    <Stack py={2} gap={1} alignItems={"center"} sx={{ backgroundColor: "rgba(223, 218, 248, 0.2)", borderRadius: 1.4, border: 1, borderColor: 'rgba(223, 218, 248, 0.9)', width: 220 }}>
                                        <Typography sx={{ fontSize: 16, fontWeight: "600", color: "primary.main" }}>{t("bo.shipInfoPg.custPaidAmt")}</Typography>
                                        <Typography sx={{ fontSize: 20, fontWeight: "bold" }}><span name="Rs">&#8377;</span> {getLocalStrg(shipmtData?.shipmtPricing?.custPaidAmt)}</Typography>
                                    </Stack>
                                    <Stack py={2} gap={1} alignItems={"center"} sx={{ backgroundColor: "rgba(223, 218, 248, 0.2)", borderRadius: 1.4, border: 1, borderColor: 'rgba(223, 218, 248, 0.9)', width: 220 }}>
                                        <Typography sx={{ fontSize: 16, fontWeight: "600", color: "primary.main" }}>{t("bo.shipInfoPg.custPenAmt")}</Typography>
                                        <Typography sx={{ fontSize: 20, fontWeight: "bold" }}><span name="Rs">&#8377;</span> {getLocalStrg(shipmtData?.shipmtPricing?.cust_finalPrc - shipmtData?.shipmtPricing?.custPaidAmt)}</Typography>
                                    </Stack>
                                </Stack>
                            </BgBox>

                            <BgBox px={1} py={3} height={450}>
                                <Typography sx={titleTxtStyle} mx={4} mb={1.5}>{t("bo.custReqInfoPg.payDet")}</Typography>
                                <CustPymtTable pymtData={shipmtData?.shipmtCustPymts} />
                            </BgBox>

                        </Stack>
                        <Stack width={isLarge ? "49.5%" : "70%"} gap={1}>
                            <BgBox px={4} py={3} >
                                <Typography sx={titleTxtStyle} mb={1.5}>{t("bo.shipInfoPg.doc")}</Typography>
                                <Stack direction={"row"} alignItems="center" gap={2.5} >
                                    <FileViewLabel label={t("bo.shipInfoPg.billCopy")} icon={fileIconSVG} fileKey={shipmtData?.shipmtDocs?.billCopy} value={shipmtData?.shipmtDocs?.billCopy ? "Bill Copy" : "No Bill Copy"} minWidth={270} maxWidth={270} />
                                    <FileViewLabel label={`Eway Bill A (${shipmtData?.shipmtDocs?.ewayA_no})`} value={shipmtData?.shipmtDocs?.ewayA ? shipmtData?.shipmtDocs?.ewayA_no : "No Eway Bill A"} icon={fileIconSVG} fileKey={shipmtData?.shipmtDocs?.ewayA} minWidth={270} maxWidth={270} />
                                </Stack>
                                <Stack direction={"row"} alignItems="center" gap={2.5} mt={2}>
                                    <FileViewLabel label={`Eway Bill B (${shipmtData?.shipmtDocs?.ewayB_no})`} value={shipmtData?.shipmtDocs?.ewayB ? shipmtData?.shipmtDocs?.ewayB_no : "No Eway Bill B"} icon={fileIconSVG} fileKey={shipmtData?.shipmtDocs?.ewayB} minWidth={270} maxWidth={270} />
                                    <FileViewLabel label={"POD"} icon={fileIconSVG} fileKey={shipmtData?.shipmtDocs?.pod_file} value={shipmtData?.shipmtDocs?.pod_file ? "POD" : "No POD"} minWidth={270} maxWidth={270} />
                                </Stack>
                                <Stack direction={"row"} alignItems="center" gap={2.5} mt={2}>
                                    <InvViewLabel label={"Consignor Receipt"} shipmtId={shipmtData?.shipment?.shipmtId} minWidth={270} maxWidth={270} invType={"consignorInv"} />
                                </Stack>
                            </BgBox>

                            <BgBox px={4} py={3} >
                                <Typography sx={titleTxtStyle} mb={1}>{t("bo.shipInfoPg.loadDet")}</Typography>
                                <UnderLineTxt boxStyle={boxStyle} label={"Load Type"} value={getFormatedUnit(shipmtData?.shipmtLoad?.loadType)} line />
                                <UnderLineTxt boxStyle={boxStyle} label={t("bo.custReqInfoPg.matCategory")} value={capFirstLetter(shipmtData?.shipmtLoad?.matCategory)} line />
                                <UnderLineTxt boxStyle={boxStyle} label={t("bo.shipInfoPg.matType")} value={shipmtData?.shipmtLoad?.matType == 'Others' ? `${shipmtData?.shipmtLoad?.matTypeFreeTxt} (FT)` : shipmtData?.shipmtLoad?.matType} line />
                                <UnderLineTxt boxStyle={boxStyle} label={t("bo.shipInfoPg.wei")} icon={weightSVG} value={formatWeight({ val: shipmtData?.shipmtLoad?.weight, unit: shipmtData?.shipmtLoad?.weightUnit })} line />
                                <UnderLineTxt boxStyle={boxStyle} label={t("bo.shipInfoPg.quan")} value={shipmtData?.shipmtLoad?.quantity} line />
                                <UnderLineTxt boxStyle={boxStyle} label={t("bo.shipInfoPg.flam/frag")} value={capFirstLetter(shipmtData?.shipmtLoad?.matNature)} line />
                                {shipmtData?.shipmtLoad?.matCategory == 'volume' && <UnderLineTxt boxStyle={boxStyle} label={t("bo.shipInfoPg.len")} value={shipmtData?.shipmtLoad?.matLength + getFormatedUnit(shipmtData?.shipmtLoad?.matDimsUnit)} line />}
                                {shipmtData?.shipmtLoad?.matCategory == 'volume' && <UnderLineTxt boxStyle={boxStyle} label={t("bo.shipInfoPg.wid")} value={shipmtData?.shipmtLoad?.matWidth + getFormatedUnit(shipmtData?.shipmtLoad?.matDimsUnit)} line />}
                                {shipmtData?.shipmtLoad?.matCategory == 'volume' && <UnderLineTxt boxStyle={boxStyle} label={t("bo.shipInfoPg.height")} value={shipmtData?.shipmtLoad?.matHeight + getFormatedUnit(shipmtData?.shipmtLoad?.matDimsUnit)} line />}
                                <UnderLineTxt boxStyle={boxStyle} label={t("bo.shipInfoPg.desc")} value={shipmtData?.shipmtLoad?.loadDesc} />
                                <Box height={2} />
                            </BgBox>

                            <BgBox px={4} py={3} >
                                <Typography sx={titleTxtStyle} mb={1.5}>{t("bo.shipInfoPg.trkDet")}</Typography>
                                <Stack direction={"row"} alignItems={"start"} gap={1.5}>
                                    <Stack gap={2.5}>
                                        <LabelTxt label={t("bo.shipInfoPg.regTrkNum")} value={shipmtData?.shipmtTruck?.truckRegNo} minWidth={270} maxWidth={270} />
                                        <LabelTxt label={t("bo.shipInfoPg.pType")} value={shipmtData?.shipmtTruck?.trkPermitType} minWidth={270} maxWidth={270} />
                                        <LabelTxtWithIcon label={t("bo.shipInfoPg.trkMaxLoadCap")} value={formatWeight({ val: shipmtData?.shipmtTruck?.trkMaxCap, unit: shipmtData?.shipmtTruck?.trkMaxCapUnit })} icon={weightSVG} minWidth={270} maxWidth={270} />
                                        <LabelTxt label={t("bo.shipInfoPg.trkType")} value={getTruckName(JSON.parse(shipmtData?.shipmtTruck?.truck_type))} minWidth={330} maxWidth={330} />
                                    </Stack>

                                    <Stack gap={2.5}>
                                        <FileViewLabel label={"RC"} fileKey={shipmtData?.shipmtTruck?.trkRC_file} value={shipmtData?.shipmtTruck?.trkRC_file ? "RC" : "No RC"} minWidth={270} maxWidth={270} />
                                        <LabelTxtWithIcon label={t("bo.shipInfoPg.insExpDate")} value={formatDateWithJs(shipmtData?.shipmtTruck?.trkIns_expDate)} icon={calendSVG} minWidth={270} maxWidth={270} />
                                        <LabelTxtWithIcon label={t("bo.shipInfoPg.fcExpDate")} value={formatDateWithJs(shipmtData?.shipmtTruck?.trkFc_expDate)} icon={calendSVG} minWidth={270} maxWidth={270} />
                                        <LabelTxtWithIconBtn onClick={() => window.open(shipmtData?.shipmtDriver?.trackingGPSLink, "_blank")} label={t("bo.shipInfoPg.trackGps")} value={shipmtData?.shipmtDriver?.trackingGPSLink && "GPS LINK"} valueClr={"rgba(5, 49, 227, 1)"} icon={trackSVG} minWidth={270} maxWidth={270} />
                                    </Stack>
                                </Stack>
                                <Box height={3} />
                            </BgBox>

                            <BgBox px={4} py={3} >
                                <Typography sx={titleTxtStyle} mb={1.5}>{t("bo.shipInfoPg.shipmtTrack")}</Typography>
                                <Stepper activeStep={shipmtData?.shipmtStatus.length - 1} orientation="vertical" sx={{ paddingLeft: 20 }}>
                                    {(shipmtData?.shipmtStatus).map((i) => (
                                        <Step key={i?.id}>
                                            <StepLabel optional={<Typography variant="caption">{formatDateTime(i?.c_at)}</Typography>}>{formatShipmtStatus(i?.status)}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </BgBox>
                        </Stack>
                    </Stack>
                </Stack>
                {shipmtData?.shipmtPricing?.cust_finalPrc >
                    shipmtData?.shipmtPricing?.custPaidAmt && <Button variant='contained' sx={{ position: "fixed", bottom: 50, right: 60, px: 3, py: 1.5 }}
                        onClick={() => handlePayNow(shipmtData)} startIcon={<CurrencyRupee fontSize='small' />}><Typography>Pay Now</Typography> </Button>}
            </ScrollBox>

            {shipmtData && <Feedback modalOpen={custRatMdl} handleModalClose={handlesetCustRatMdlMdlClose} shipmtData={shipmtData} />}
            {shipmtData && <UpdateDetails modalOpen={updateDetMdl} handleModalClose={handlesetUpdateDetMdlMdlClose} shipmtData={shipmtData} />}

        </>
    )
}

function EditBtn({ onClick }) {
    return <Button variant='text' sx={{ position: "absolute", top: 10, right: 15 }} onClick={onClick} startIcon={<Edit fontSize='small' />}><Typography>Edit</Typography> </Button>
}

function LabelTxtDel({ label, value, minWidth, maxWidth, bgcolor, fontSize, custData }) {
    return (
        <Stack minWidth={minWidth} maxWidth={maxWidth} bgcolor={bgcolor}>
            <Stack direction={'row'} alignItems={"center"}>
                <Typography sx={{ fontSize: 14, fontWeight: "550" }}>{label}</Typography>
                <Box width={8} />
                {custData?.isCustDelete === 1 && <Box component={"img"} src={deletedSVG} alt={"deletedSVG"} sx={{ height: 18 }} />}
            </Stack>

            <Typography sx={{ fontSize: fontSize || 14.5, fontWeight: "600", color: "rgba(0, 0, 0, 0.5)", mt: 0.5 }}>{value}</Typography>
        </Stack>
    )
}

export default AgShipmtInfoPage