import { postData } from "./rest-api-helper"

export async function postRazorpay(data) {
    const details = {
        urlPath: "/razorpay",
        body: {
            ...data
        }
    }
    const res = await postData(details);
    console.log(res, "---------------postRazorpay");
    return res;
}

export async function postRazorpayValidate(data) {
    const details = {
        urlPath: "/razorpay/validate",
        body: {
            ...data
        }
    }
    const res = await postData(details);
    console.log(res, "---------------postRazorpayValidate");
    return res;
}

export async function postRazorpayError(data) {
    const details = {
        urlPath: "/razorpay/error",
        body: {
            ...data
        }
    }
    const res = await postData(details);
    console.log(res, "---------------postRazorpayError");
    return res;
}