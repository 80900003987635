const route = {

    saBoLists: "/sa/Backoffices",
    saAddBo: "/sa/AddBackoffice",
    login: '/login',
    editBo: "/sa/EditBackOffice/",
    addUser: "/sa/AddUser",
    editUser: "/sa/EditUser/",
    boInfo: "/sa/BoInfo/",
    userInfo: "/sa/UserInfo/",
    users: "/sa/Users",
    about: "/sa/about",
    editAbout: "/sa/EditAbout",
    priceFactors: "/sa/PriceFactors",
    boDashboard: "/bo/Dashboard",
    boCustList: "/bo/CustList",
    boAddCust: "/bo/AddCust",
    boEditCust: "/bo/EditCust/",
    boCustInfo: "/bo/CustInfo/",
    boTrkOpList: "/bo/TrkOpList/",
    boTrkOpInfo: "/bo/TrkOpInfo/",
    boTrkInfo: "/bo/TrkInfo/",
    boCustReqList: "/bo/CustReqList/",
    boTrkOpReqList: "/bo/TrkOpReqList/",
    boAddCustReq: "/bo/AddCustReq",
    boEditCustReq: "/bo/EditCustReq/",
    boCustReqInfo: "/bo/CustReqInfo/",
    boAddTrkOpReq: "/bo/AddTrkOpReq",
    boEditTrkOpReq: "/bo/EditTrkOpReq/",
    BoUserInfo: "/bo/profile",
    BoStaffInfo: "/bo/staff/",
    BoEditUser: "/bo/EditUser/",
    BoAbout: "/bo/about",
    boShipmtList: "/bo/shipments/",
    boAddShipmt: "/bo/addShipmt",
    boAddTruck: "/bo/addtruck",
    boEditTrk: "/bo/editTruck/",
    boAddTrkOp: "/bo/addTrkOp",
    boEditTrkOp: "/bo/editTrkOp/",
    boShipmtInfo: "/bo/shipmentInfo/",
    boTrkOpReqInfo: "/bo/trkOpReqInfo/",
    saReportConfigs: "/sa/ReportConfigs",
    boReportsList: "/bo/ReportsList",
    saLog: "/sa/LogPage",
    boTrkListByTpPage: "/bo/trucks/",
    boAadhaarRequests: "/bo/aadhaarRequests",
    boTrkOpRepts: "/bo/OperatorReports",
    boCustShipments: "/bo/custShipments",
    boTrkOpShipments: "/bo/trkOpShipments",
    boCustReqs: "/bo/custReqs",
    boTrkOpReqs: "/bo/trkOpReqs",
    forgotPassword: '/ForgotPassword',
    verifyOTP: '/VerifyOTP',
    boReqPymtRept: "/bo/ReqPaymentReport",
    bocustVfyReqs: "/bo/custVfyReqs",
    botrkOpVfyReqs: "/bo/trkOpVfyReqs",
    boPenTrks: "/bo/pendingTrucks",
    boRjcTrks: "/bo/rejectedTrucks",
    boAgList: "/bo/agents",
    boAddAg: "/bo/agent/create",
    boEditAgent: "/bo/agent/edit/",
    boAgentInfo: "/bo/agent/info/",
    boAgCustReqs: "/bo/agent/custReqs",
    boAgCustShipmts: "/bo/agent/shipments",
    agentLogin: "/agentLogin",
    agDashboard: "/ag/Dashboard",
    agCustList: "/ag/CustList",
    agEditCust: "/ag/EditCust/",
    agAddCust: "/ag/AddCust",
    agCustReqList: "/ag/CustReqList/",
    agShipmtList: "/ag/shipments/",
    agAddCustReq: "/ag/AddCustReq",
    agEditCustReq: "/ag/EditCustReq/",
    agCustReqInfo: "/ag/CustReqInfo/",
    agShipmtInfo: "/ag/shipmentInfo/",
    agCustInfo: "/ag/CustInfo/",
    agCustShipments: "/ag/custShipments",
    agCustReqs: "/ag/custReqs",
    agProfile: "/ag/profile",
    agAbout: "/ag/about",
    agEdit: "/ag/edit",
    boAgSettle: "/bo/agent/settle",
    boAgPymts: "/bo/agent/payments",
    boAgSinglePymt: "/bo/agent/payment/",
    agPymts: "/ag/payments",
    boDelCustList: "/bo/CustList/deleted",
    boDelTpList: "/bo/TrkOpList/deleted",
    agCustListDel: "/ag/CustList/deleted",
    boGenConEwbPage: "/bo/GenConEwbPage",
}

export default route;