import { Button, Rating, Stack, Typography, useMediaQuery } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import ScrollBox from '../../../components/ScrollBox';
import complShipmtCount from '../../../assets/svg/complShipmtCount.svg';
import CountCard from '../../../components/CountCard';
import currShipmtCount from '../../../assets/svg/currShipmtCount.svg';
import { ArrowForward, Edit } from '@mui/icons-material';
import CustReqCard from '../../../components/CustReqCard';
import Slider from "react-slick";
import { useState } from 'react';
import { useAuthContext } from '../../../context/AuthContext';
import { customAlert } from '../../../components/notify';
import { useEffect } from 'react';
import FillLabelTxtField from '../../../components/FillLabelTxtField';
import CustomRadio from '../../../components/CustomRadio';
import { useNavigate, useParams } from 'react-router-dom';
import { getSingleCust } from '../../../services/cust-service';
import route from '../../../Routes';
import ChangePwdPopUp from '../../../components/ChangePwdPopUp';
import { capFirstLetter } from '../../../utils/format';
import currCustReqImg from '../../../assets/svg/currCustReq.svg';
import notVerifiedImg from '../../../assets/svg/notVerified.svg';
import totalReqImg from '../../../assets/svg/totalReq.svg';
import verifiedImg from '../../../assets/svg/verified.svg';
import ShipmtCard from '../../../components/ShipmtCard';
import { useTranslation } from "react-i18next";
import FileViewLabel from '../../../components/FileViewLabel';
import AddClick from '../../../components/AddClick';
import UpdateCustBankDetails from './UpdateCustBankDetails';
import SingleCustReqCard from '../../../components/SingleCustReqCard';
import AgFillLabelTxtFieldVfy from '../../../components/AgFillLabelTxtFieldVfy';
import PanVerify from '../../../components/ServiceVfy/PanVerify';
import GSTVerify from '../../../components/ServiceVfy/GSTVerify';
import AadhaarVfy from '../../../components/ServiceVfy/AadhaarVfy';
import deletedSVG from '../../../assets/svg/deleted.svg';


function AgCustInfoPage() {
  const { setLoadingScreen } = useAuthContext();
  const isLarge = useMediaQuery("(min-width: 600px)");
  const { custId } = useParams();
  const [custData, setCustData] = useState(null);
  const navigate = useNavigate();
  const [modalOpen, setmodalOpen] = useState(false);
  const handleModalClose = () => setmodalOpen(false);
  const handleModalOpen = () => setmodalOpen(true);
  const { t } = useTranslation();
  let txtFielProps = { fontSize: 15, height: 38 };


  const [bankDetMdl, setBankDetMdl] = useState(false);
  const handleBankDetMdlMdlClose = () => {
    setBankDetMdl(false);
    window.location.reload();
  };
  const handleBankDetMdlMdlOpen = () => setBankDetMdl(true);

  const [panVfyMdl, setPanVfyMdl] = useState(false);
  const handlePanVfyMdlMdlClose = () => {
    setPanVfyMdl(false);
    window.location.reload();
  };
  const handlePanVfyMdlMdlOpen = () => setPanVfyMdl(true);

  const [adrVfyMdl, setAdrVfyMdl] = useState(false);
  const handleAdrVfyMdlMdlClose = () => {
    setAdrVfyMdl(false);
    window.location.reload();
  };
  const handlAdrtVfyMdlMdlOpen = () => setAdrVfyMdl(true);

  const [gstVfyMdl, setGstVfyMdl] = useState(false);
  const handleGstVfyMdlMdlClose = () => {
    setGstVfyMdl(false);
    window.location.reload();
  };
  const handleGstVfyMdlMdlOpen = () => setGstVfyMdl(true);

  async function getData() {
    setLoadingScreen(true);
    try {
      const custData = await getSingleCust(custId);
      setCustData(custData);
    } catch (err) {
      console.log(err);
      customAlert(err);
    }
    setLoadingScreen(false);
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const settings = {
    className: "slider variable-width",
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true
  };

  const radioArr = [
    { value: "individual", label: t("bo.custInfoPg.individual") },
    { value: "company", label: t("bo.custInfoPg.comp") }];

  async function handleEdit() {
    navigate(route.agEditCust + custData.custId, { state: { custData: custData } });
  }

  function verified() {
    if (custData.aadhar_verified === 1 || custData.pan_verified === 1 || custData.gst_verified === 1)
      return <img src={verifiedImg} alt="verifiedImg" />
    else
      return <Stack direction={'row'} alignItems={"center"}>
        <img src={notVerifiedImg} alt="notVerifiedImg" />
        <Typography sx={{ color: "#EB9A55", fontSize: 14, fontWeight: 500, ml: 0.3 }}>Need Verification</Typography>
      </Stack>
  }

  return (
    <>
      {custData ? <ScrollBox height={"100%"}>
        <Stack direction={'row'} justifyContent='space-between' mt={1.6} mb={1.3} >
          <Stack direction={'row'} alignItems={"center"}>
            <Typography variant='h5' sx={{ fontWeight: "bold", mr: 1 }}>{capFirstLetter(custData?.fName) + " " + capFirstLetter(custData?.lName)}{t("bo.custInfoPg.cust")}</Typography>
            {verified()}
            <Box width={8} />
            {custData?.isCustDelete === 1 && <img src={deletedSVG} alt="deletedSVG" />}
          </Stack>
          <Stack direction={'row'} mr={5}>
            <Button variant='outlined' color='primary' sx={{ border: 2, height: 35, '&:hover': { border: 2 } }} onClick={handleEdit}> {t("bo.custInfoPg.editInfo")}</Button>
            <Box width={8} />
            <Button variant='contained' color='primary' sx={{ height: 35 }} onClick={handleModalOpen}>{t("bo.custInfoPg.changePw")}</Button>
          </Stack>
        </Stack>

        <Stack direction={'row'} justifyContent={"space-between"} mb={2}>
          <AddClick path={route.agCustReqs + "?custId=" + custData?.custId + "&custName=" + custData?.fName + "&time=current" + "&reqStatus=approved"}>
            <CountCard imgPath={currCustReqImg} title={t("bo.custInfoPg.currReqCount")} count={custData?.currReq?.length || 0} />
          </AddClick>
          <AddClick path={route.agCustReqs + "?custId=" + custData?.custId + "&custName=" + custData?.fName + "&time=history" + "&reqStatus=approved"}>
            <CountCard imgPath={totalReqImg} title={t("bo.custInfoPg.reqHisCount")} count={custData?.totalReqs} />
          </AddClick>
          <AddClick path={route.agCustShipments + "?custId=" + custData?.custId + "&custName=" + custData?.fName + "&time=current"}>
            <CountCard imgPath={currShipmtCount} title={t("bo.custInfoPg.currShpCount")} count={custData?.currShipmt?.length || 0} />
          </AddClick>
          <AddClick path={route.agCustShipments + "?custId=" + custData?.custId + "&custName=" + custData?.fName + "&time=history"}>
            <CountCard imgPath={complShipmtCount} title={t("bo.custInfoPg.complShpCount")} count={custData.completedShipmts} />
          </AddClick>
        </Stack>

        <Stack direction={isLarge ? 'row' : "column"} alignItems={''} sx={{ mb: 2, height: 1000 }} >
          <Box py={2} pl={5} mr={2} sx={{ backgroundColor: "#ffff", borderRadius: 1.4, height: "100%", border: 1, borderColor: '#E3E3E3', width: isLarge ? "49%" : "52%" }}>
            <Typography variant='h5' sx={{ fontWeight: "bold", color: "rgba(131, 146, 171, 0.6)", fontSize: 22, mb: 2, mt: 0.5 }}>{t("bo.custInfoPg.perInfo")}</Typography>
            <Stack gap={3} mt={1}>
              <FillLabelTxtField title={t("bo.custInfoPg.fName")} defaultValue={custData?.fName} readOnly={true} {...txtFielProps} />
              <FillLabelTxtField title={t("bo.custInfoPg.lName")} defaultValue={custData?.lName} readOnly={true} {...txtFielProps} />
              <FillLabelTxtField title={t("bo.custInfoPg.mName1")} defaultValue={custData?.username} readOnly={true} {...txtFielProps} />
              <FillLabelTxtField title={t("bo.custInfoPg.mName2")} defaultValue={custData?.mobile2} readOnly={true} {...txtFielProps} />
              <FillLabelTxtField title={t("bo.custInfoPg.email")} defaultValue={custData?.email} readOnly={true} {...txtFielProps} />

              {custData?.type === 'company' && <Stack direction={"row"} alignItems={"center"} gap={2}>
                <AgFillLabelTxtFieldVfy onVerifyClick={handleGstVfyMdlMdlOpen} status={custData?.gst_verified} name="gst_no" readOnly={true} title={t("bo.custInfoPg.gstNum")} defaultValue={custData?.gst_no} {...txtFielProps} width={300} />
                <FileViewLabel label={t("bo.addTrkOpPg.gstFile")} fileKey={custData?.gst_file} value={custData?.gst_file ? "GST" : "No GST"} minWidth={240} maxWidth={240} />
              </Stack>}

              <Stack direction={"row"} alignItems={"center"} gap={2}>
                <AgFillLabelTxtFieldVfy onVerifyClick={handlePanVfyMdlMdlOpen} status={custData?.pan_verified} title={t("bo.custInfoPg.panNum")} defaultValue={custData?.pan_no} readOnly={true}  {...txtFielProps} width={300} />
                <FileViewLabel label={t("bo.addTrkOpPg.panFile")} fileKey={custData?.pan_file} value={custData?.pan_file ? "Pan" : "No Pan"} minWidth={240} maxWidth={240} />
              </Stack>
              <Box height={10} />
            </Stack>
          </Box>

          <Box py={2} pl={10} sx={{ backgroundColor: "#ffff", borderRadius: 1.4, height: "100%", border: 1, borderColor: '#E3E3E3', width: "49%" }}>
            <Typography variant='h5' sx={{ fontWeight: "bold", color: "rgba(131, 146, 171, 0.6)", fontSize: 22, mb: 2, mt: 0.5 }}>{t("bo.custInfoPg.addiInfo")}</Typography>
            <Stack gap={3} mt={1}>
              <CustomRadio title={t("bo.custInfoPg.selType")} name={"type"} defaultValue={custData?.type} disabled={true} radioArr={radioArr} />
              {custData?.type !== 'company' && <AgFillLabelTxtFieldVfy onVerifyClick={handlAdrtVfyMdlMdlOpen} status={custData?.aadhar_verified} title={t("bo.custInfoPg.aadhaarNum")} defaultValue={custData?.aadhar_no} readOnly={true}  {...txtFielProps} />}
              {custData?.type === 'company' && <Stack gap={3}>
                <FillLabelTxtField name="comName" title={t("bo.custInfoPg.cName")} defaultValue={custData?.comName} readOnly={true}  {...txtFielProps} />
                <FillLabelTxtField name="comContact_no" title={t("bo.custInfoPg.cContactNum")} defaultValue={custData?.comContact_no} readOnly={true}  {...txtFielProps} />
                <FillLabelTxtField name="comAddress" title={t("bo.custInfoPg.cAddress")} multiline={true} defaultValue={custData?.comAddress} readOnly={true}  {...txtFielProps} height={103} />
                <FillLabelTxtField name="comDescription" title={t("bo.custInfoPg.cDesc")} multiline={true} defaultValue={custData?.comDescription} readOnly={true}  {...txtFielProps} height={103} />

              </Stack>}

              {/* <FillLabelTxtField title={"First Name"} defaultValue={custData?.staff_name} readOnly={true} /> */}
              <Box height={10} />
            </Stack>
          </Box>
        </Stack>

        <Stack direction={isLarge ? 'row' : "column"} alignItems={''} sx={{ height: custData?.type === 'company' ? "120%" : "100%", mb: 2 }} >
          {custData && <Box py={2} pl={5} mb={1.5} mr={1.5} mt={0.5} sx={{ backgroundColor: "#ffff", borderRadius: 1.4, height: "100%", border: 1, borderColor: '#E3E3E3', width: isLarge ? "49%" : "52%" }}>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
              <Typography sx={{ fontWeight: "bold", color: "rgba(131, 146, 171, 0.6)", fontSize: 22 }}>{t("bo.custInfoPg.bankDetails")}</Typography>
              <Button sx={{ mr: 3 }} variant="outlined" startIcon={<Edit />} onClick={handleBankDetMdlMdlOpen}>{t("bo.custInfoPg.updateBankDetails")}</Button>
            </Stack>
            <Stack direction={"row"} mt={2} gap={20} mb={2} >
              <Stack gap={3}>
                {custData?.accName && <FillLabelTxtField name="accName" title={t("bo.custInfoPg.accName")} defaultValue={custData?.accName} readOnly={true}  {...txtFielProps} />}
                {custData?.ifsc && <FillLabelTxtField name="ifsc" title={"IFSC"} defaultValue={custData?.ifsc} readOnly={true}  {...txtFielProps} />}
                {custData?.accNo && <FillLabelTxtField name="accNo" title={t("bo.custInfoPg.accNo")} defaultValue={custData?.accNo} readOnly={true}  {...txtFielProps} />}
              </Stack>
              <Stack>
              </Stack>
            </Stack>
          </Box>}
          {custData && <Box py={2} pl={5} mb={1.5} mr={1.5} mt={0.5} sx={{ backgroundColor: "#ffff", borderRadius: 1.4, height: "100%", border: 1, borderColor: '#E3E3E3', width: "49%" }}>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
              <Typography sx={{ fontWeight: "bold", color: "rgba(131, 146, 171, 0.6)", fontSize: 22 }}>{t("bo.custInfoPg.custRatingByBo")}</Typography>
            </Stack>
            <Stack direction={"row"} mt={2} gap={20} mb={2} >
              <Stack gap={3}>
                <Stack sx={{ width: 500 }}>
                  <Typography style={{
                    fontWeight: "600",
                    fontSize: 15
                  }} component={'label'} sx={{ mb: 1 }} >{t("bo.custInfoPg.rating")}</Typography>
                  {custData && <Rating value={custData?.boCustRating} readOnly precision={0.1} />}
                </Stack>
                {custData && <FillLabelTxtField name="boCustFeedback" title={t("bo.custInfoPg.feedback")} defaultValue={custData?.boCustFeedback} readOnly={true}  {...txtFielProps} multiline={true} height={103} />}
              </Stack>
              <Stack>
              </Stack>
            </Stack>
          </Box>}
        </Stack>

        <Stack direction={'row'} justifyContent='space-between' mb={1}>
          <Typography sx={{ fontWeight: "bold", color: "rgba(131, 146, 171, 0.6)", fontSize: 22 }}>{t("bo.custInfoPg.penReqList")}</Typography>
          <Button color="secondary" endIcon={<ArrowForward />} sx={{ mr: 2 }} onClick={() => navigate(route.agCustReqs + "?custId=" + custData?.custId + "&custName=" + custData?.fName + "&time=all" + "&reqStatus=pending")}> {t("bo.custInfoPg.viewAll")}</Button>
        </Stack>
        {custData?.penReqRes?.length > 0 ? <Slider {...settings}>
          {custData?.penReqRes?.map((i) => <SingleCustReqCard key={i.id} i={i} reqStatusType={"pending"} />)}
        </Slider> :
          <Typography sx={{ textAlign: "center" }}>{t("bo.custInfoPg.empList")}</Typography>}


        <Stack direction={'row'} justifyContent='space-between' mb={1} mt={2}>
          <Typography sx={{ fontWeight: "bold", color: "rgba(131, 146, 171, 0.6)", fontSize: 22 }}>{t("bo.custInfoPg.currReq")}</Typography>
          <Button color="secondary" endIcon={<ArrowForward />} sx={{ mr: 2 }} onClick={() => navigate(route.agCustReqs + "?custId=" + custData?.custId + "&custName=" + custData?.fName + "&time=current" + "&reqStatus=approved")}> {t("bo.custInfoPg.viewAll")}</Button>
        </Stack>
        {custData?.currReq?.length > 0 ? <Slider {...settings}>
          {custData?.currReq?.map((i) => <CustReqCard key={i.id} i={i} />)}
        </Slider> :
          <Typography sx={{ textAlign: "center" }}>{t("bo.custInfoPg.empList")}</Typography>}

        <Stack direction={'row'} justifyContent='space-between' mt={2} mb={1}>
          <Typography sx={{ fontWeight: "bold", color: "rgba(131, 146, 171, 0.6)", fontSize: 22 }}>{t("bo.custInfoPg.currShp")}</Typography>
          <Button color="secondary" endIcon={<ArrowForward />} sx={{ mr: 2 }} onClick={() => navigate(route.agCustShipments + "?custId=" + custData?.custId + "&custName=" + custData?.fName + "&time=current")}>{t("bo.custInfoPg.viewAll")}</Button>
        </Stack>
        {custData?.currShipmt?.length > 0 ? <Slider {...settings}>
          {custData?.currShipmt?.map((i) => <ShipmtCard key={i.id} i={i} />)}
        </Slider> :
          <Typography sx={{ textAlign: "center" }}>{t("bo.custInfoPg.empList")}</Typography>}

        <Stack direction={'row'} justifyContent='space-between' mb={1} mt={2}>
          <Typography sx={{ fontWeight: "bold", color: "rgba(131, 146, 171, 0.6)", fontSize: 22 }}>{t("bo.custInfoPg.rjcReqList")}</Typography>
          <Button color="secondary" endIcon={<ArrowForward />} sx={{ mr: 2 }} onClick={() => navigate(route.agCustReqs + "?custId=" + custData?.custId + "&custName=" + custData?.fName + "&time=all" + "&reqStatus=rejected")}> {t("bo.custInfoPg.viewAll")}</Button>
        </Stack>
        {custData?.rjcReqRes?.length > 0 ? <Slider {...settings}>
          {custData?.rjcReqRes?.map((i) => <SingleCustReqCard key={i.id} i={i} reqStatusType={"rejected"} />)}
        </Slider> :
          <Typography sx={{ textAlign: "center" }}>{t("bo.custInfoPg.empList")}</Typography>}


        <ChangePwdPopUp modalOpen={modalOpen} handleModalClose={handleModalClose} userId={custData.custId} />

      </ScrollBox> : <Typography component={'h1'}>Something went wrong</Typography>

      }

      {custData && <UpdateCustBankDetails modalOpen={bankDetMdl} handleModalClose={handleBankDetMdlMdlClose} custId={custId} custData={custData} />}
      {custData && <PanVerify modalOpen={panVfyMdl} handleModalClose={handlePanVfyMdlMdlClose} userId={custId} />}
      {custData && <GSTVerify modalOpen={gstVfyMdl} handleModalClose={handleGstVfyMdlMdlClose} userId={custId} />}
      {custData && <AadhaarVfy modalOpen={adrVfyMdl} handleModalClose={handleAdrVfyMdlMdlClose} userId={custId} />}
    </>
  )
}



export default AgCustInfoPage